export const useOpenFileInNewTab = async (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;

  // link.setAttribute('download', fileName);
  link.setAttribute('target', '_blank');
  link.click();

  link.remove();
};
